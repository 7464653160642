<template>
  <div class="flex flex-col font-Inter p-6">
    <div class="flex flex-col shadow bg-white px-6 pt-6 mb-5">
      <div class="flex flex-row mb-4 justify-end items-center">
        <router-link class="mr-2" to="payments/create">
          <el-button type="primary" icon="el-icon-circle-plus"
            >Créer
          </el-button>
        </router-link>
        <inputClient class="mr-2 w-2/3" @customer="handleCustomer" />
        <el-date-picker
          class="mr-2"
          v-model="listQuery.start"
          type="date"
          format="dd MMM.yyyy"
          value-format="yyyy-MM-dd"
          placeholder="Date debut"
        >
        </el-date-picker>
        <el-date-picker
          class="mr-2 w-1/2"
          v-model="listQuery.end"
          type="date"
          format="dd MMM.yyyy"
          value-format="yyyy-MM-dd"
          placeholder="Date fin"
        >
        </el-date-picker>

        <el-button class="" @click="fetchData()">Rechercher</el-button>
      </div>
      <div class="customer-white">
        <div class="flex justify-end">
          <el-dropdown class="mb-2" @command="handleExport">
            <el-button type="text" :loading="exportLoading">
              Exporter <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="PDF">En pdf</el-dropdown-item>
              <el-dropdown-item command="EXCEL">En excel</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <skeleton-list :row="8" v-if="listLoading" />
        <el-table :data="items" v-else>
          <el-table-column prop="date" label="DATE CRéation" width="170">
            <template slot-scope="{ row }">
              <span>{{ row.created_at }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="payment_no" label="N° PAIEMENT" width="200">
            <template slot-scope="{ row }">
              <router-link :to="`payments/${row.payment_no}`">
                <span class="text-blue-500">{{ row.payment_no }}</span>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="payment_mode" label="METHODE" width="150">
          </el-table-column>
          <el-table-column
            prop="cancel"
            label="STATUT"
            align="center"
            width="150"
          >
            <template slot-scope="{ row }">
              <Status :name="row.status" />
            </template>
          </el-table-column>

          <el-table-column prop="customer" label="CLIENTS">
            <template slot-scope="{ row }">
              <div class="flex flex-col">
                <span class="uppercase">{{ row.contact_name }}</span>
                <span class="text-gray-500 text-xs"
                  >{{ row.contact_street }} {{ row.contact_phone }}</span
                >
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="note" label="Note">
            <template slot-scope="{ row }">
              <div class="flex flex-col">
                <span class="">{{ row.payment_type }}</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="amount"
            label="MONTANT "
            align="right"
            width="170"
          >
            <template slot-scope="{ row }">
              <span>{{ row.total | moneyFilter }} {{row.curreny}} </span>
            </template>
          </el-table-column>

          <el-table-column width="150" align="right">
            <template slot-scope="{ row }">
              <el-button
                :loading="
                  numberPrinte === row.payment_no ? loadingPrint : false
                "
                size="mini"
                icon="el-icon-printer"
                circle
                @click="exportRecuPDF(row.payment_no)"
              >
              </el-button>

              <el-dropdown
                style="margin-left: 10px"
                @command="handleCommand"
                trigger="click"
              >
                <el-button size="mini" icon="el-icon-menu" circle></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="{ get: row }">
                    <i class="el-icon-tickets"></i>
                    Afficher
                  </el-dropdown-item>
                  <el-dropdown-item
                    :disabled="row.status === 'CANCELED'"
                    :command="{ cancel: row }"
                  >
                    <i class="el-icon-delete"></i>
                    Annuler
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="flex justify-end">
        <pagination
          v-show="listQuery.total > 0"
          :total="listQuery.total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.size"
          @pagination="fetchData()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { toThousandFilter } from "@/Filters";
import { parseDate } from "@/utils";
import { fetchPaymentInvoice, cancelPaymentInvoice } from "@/api/sales2";
import { exportRecuPDF, exportExcelPaymentSale } from "@/api/export";
import Pagination from "@/components/Pagination";
import inputClient from "@/components/searchCustomer";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";

export default {
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  components: { Pagination, inputClient, Status, SkeletonList },
  data() {
    return {
      dialogVisible: false,
      numberSelecte: "",
      numberPrinte: "",
      items: [],
      loadingPrint: false,
      listLoading: false,
      exportLoading: false,
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        contactId: undefined,
        start: undefined,
        end: undefined,
      },
    };
  },
  mounted() {
    const query = this.$route.query;
    if (query.newPayment) {
      this.$message({
        message: "Le paiement a été enregistré avec succès.",
        type: "success",
      });
    }
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.listLoading = true;
      await fetchPaymentInvoice(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportRecuPDF(e) {
      exportRecuPDF(e)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/pdf",
            })
          );
          let iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.src = url;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
        })
        .catch((err) => {
          this.loadingPrint = false;
          console.log(err);
          console.log("hello");
        });
    },
    handleCustomer(id) {
      this.listQuery.contactId = id;
      this.fetchData();
    },

    handleCanceledPayment(e) {
      const h = this.$createElement;
      this.$msgbox({
        title: "REÇU N° " + e.payment_no,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment annuler le paiement ? "),
        ]),
        showCancelButton: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            cancelPaymentInvoice(e.payment_no)
              .then(() => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                  e.status = "CANCELED";
                  this.$message({
                    type: "success",
                    message: "Le reçu a été annulé",
                  });
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },

    exportExcelPaymentDocuments() {
      this.exportLoading = true;
      exportExcelPaymentSale(this.listQuery)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "liste-paiements.xls"); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleExport(e) {
      if (
        this.listQuery.start === undefined ||
        this.listQuery.end === undefined
      ) {
        this.$message({
          showClose: true,
          message: "Merci de selectionner deux dates valide.",
          type: "error",
        });
      } else {
        switch (e) {
          case "PDF":
            this.exportQuoteListPDF();
            break;
          case "EXCEL":
            this.exportExcelPaymentDocuments();
        }
      }
    },
    handleCommand(e) {
      if (e.get) {
        this.$router.push(`/sales/payments/${e.get.payment_no}`);
      }
      if (e.cancel) {
        this.handleCanceledPayment(e.cancel);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
