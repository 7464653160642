<template>
  <div class="flex flex-col font-Inter mb-3 p-6">
    <div class="flex flex-col shadow bg-white px-6 pt-5">
      <div class="flex flex-row mb-4 items-center">
        <router-link class="mr-2" to="orders/create">
          <el-button type="primary" icon="el-icon-plus">Créer </el-button>
        </router-link>
        <inputClient class="mr-2 w-2/3" @customer="handleCustomer" />
        <el-date-picker
          class="mr-2"
          v-model="listQuery.start"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Date debut"
        >
        </el-date-picker>
        <el-date-picker
          class="mr-2 w-1/2"
          v-model="listQuery.end"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Date fin"
        >
        </el-date-picker>
        <el-button @click="getData()">Rechercher</el-button>
      </div>

      <el-tabs v-model="activeName" @tab-click="handleTab">
        <el-tab-pane label="Toutes" name="ALL"></el-tab-pane>
        <el-tab-pane
          :label="`En cours (${statistque.count_opend})`"
          name="OPEND"
        ></el-tab-pane>
        <el-tab-pane
          :label="`Brouillons (${statistque.count_draf})`"
          name="DRAFT"
        ></el-tab-pane>
        <el-tab-pane label="Terminées" name="CLOSED"></el-tab-pane>
        <el-tab-pane label="Annulées" name="CANCELED"></el-tab-pane>
      </el-tabs>
      <div
        v-if="activeName === 'OPEND'"
        class="flex justify-between items-center mb-2 text-blue font-semibold"
      >
        <div>
          Montant à facturé :
          {{ statistque.total_amount_opend | moneyFilter }} {{user.currency_code}}
        </div>
        <div>
          <el-dropdown @command="handleExport">
            <el-button type="text" :loading="exportLoading">
              Exporter <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="PDF">PDF</el-dropdown-item>
              <el-dropdown-item command="EXCEL">EXCEL</el-dropdown-item>
              <el-dropdown-item command="CSV">CSV</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div class="customer-white">
        <skeleton-list :row="8" v-if="listLoading" />
        <el-table :data="items" v-else style="width: 100%" class="mt-2">
          <el-table-column prop="date" label="DATE CRéATION" width="160">
            <template slot-scope="{ row }">
              <span>{{ row.created_at }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="invoice_no" label="N° COMMANDE" width="160">
            <template slot-scope="{ row }">
              <router-link :to="`orders/` + row._id">
                <span class="text-blue-500">{{ row.number }}</span>
              </router-link>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="date" label="D.COMMANDE" width="130">
            <template slot-scope="{ row }">
              <span>{{ row.date }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="D.ÉCHEANCE" width="150">
            <template slot-scope="{ row }">
              <div class="flex flex-col">
                <span>{{ row.due_date }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="150" label="RÉFERENCE">
            <template slot-scope="{ row }">
              <span>{{ row.reference }}</span>
            </template>
          </el-table-column>
          <el-table-column label="STATUT" width="160">
            <template slot-scope="{ row }">
              <Status :name="row.status" />
            </template>
          </el-table-column>

          <el-table-column prop="contact_organizationname" label="TITRE">
            <template slot-scope="{ row }">
              <div class="flex flex-col">
                <div class="flex flex-col">
                  <span class="uppercase">{{ row.contact_name }}</span>
                  <span class="text-gray-500 text-xs"
                    >{{ row.contact_street }} {{ row.contact_phone }}</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="total_all_taxe"
            label="TOTAL HT"
            align="right"
            width="190"
          >
            <template slot-scope="{ row }">
              <span>{{ row.sub_total | moneyFilter }} </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="total_all_taxe"
            label="TOTAL TTC "
            align="right"
            width="190"
          >
            <template slot-scope="{ row }">
              <span>{{ row.total | moneyFilter }} {{row.currency}}</span>
            </template>
          </el-table-column>
          <el-table-column width="160" label="Actions" align="right">
            <template slot-scope="{ row }">
              <el-button
                @click="exportOrderPDF(row)"
                size="mini"
                icon="el-icon-printer"
                circle
              >
              </el-button>
              <el-button
                @click="handleView(row)"
                size="mini"
                type="info"
                icon="el-icon-view"
                circle
              >
              </el-button>
              <el-dropdown
                style="margin-left: 10px"
                @command="handleCommand"
                trigger="click"
              >
                <el-button size="mini" icon="el-icon-menu" circle></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="{ get: row }">
                    <i class="el-icon-tickets"></i>
                    Afficher
                  </el-dropdown-item>
                  <el-dropdown-item :command="{ copy: row }">
                    <i class="el-icon-document-copy"></i>
                    Dupliquer
                  </el-dropdown-item>
                  <el-dropdown-item
                    :disabled="row.status !== 'DRAFT' && row.status !== 'OPEND'"
                    :command="{ update: row }"
                  >
                    <i class="el-icon-edit"></i>
                    Modifier
                  </el-dropdown-item>
                  <el-dropdown-item
                    :disabled="row.status !== 'OPEND' && row.status !== 'DRAFT'"
                    :command="{ cancel: row }"
                  >
                    <i class="el-icon-delete"></i>
                    Annuler
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-end">
        <pagination
          v-show="listQuery.total > 0"
          :total="listQuery.total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.size"
          @pagination="getData()"
        />
      </div>
    </div>
    <!-- View PDF -->
    <PDFView :url="urlPDF" :visible="dialogViewPDF" @close="handleClose" />
  </div>
</template>

<script>
import {
  fetchStatistcs,
  getAllDocument,
  cancelDocument,
  copyDocument,
} from "@/api/sales2";
import {
  exportQuoteListPDF,
  exportOrderPDF,
  exportExcelAllDocument,
} from "@/api/export";
import Pagination from "@/components/Pagination";
import { toThousandFilter } from "@/Filters";
import { parseDate } from "@/utils";
import inputClient from "@/components/searchCustomer";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";
import PDFView from "@/components/PDF/view";

export default {
  name: "Quote",
  components: { Pagination, inputClient, Status, SkeletonList, PDFView },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      urlPDF: null,
      dialogViewPDF: false,
      listLoading: false,
      exportLoading: false,
      items: [],
      countQuoteWait: 0,
      activeName: "ALL",
      exportInvoiceId: "",
      loandExportInvoice: false,
      copyQuoteId: "",
      loadingQuoate: false,
      cancelLoading: false,
      cancelQuoteId: "",
      totalItem: 0,
      dialogTransVisible: false,
      quoteTransfId: "",
      file: undefined,
      statistque: {
        count_opend: 0,
        count_today: 0,
        count_unpaid: 0,
        count_draf: 0,
        total_amount_opend: 0,
        total_amount_today: 0,
        total_amount_unpaid: 0,
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        start: undefined,
        end: undefined,
        documentNo: undefined,
        status: undefined,
        contactId: undefined,
        type: "SALE_ORDER",
      },
    };
  },
    computed :{

    },
  mounted() {
    this.getData();
    this.fetchStat();
  },
  methods: {
    async getData() {
      this.listLoading = true;

      if (this.listQuery.quoteNo === "") {
        this.listQuery.quoteNo = undefined;
      }

      await getAllDocument(this.listQuery, this.listQuery.type)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleTab(obj) {
      const name = obj.name;

      switch (name) {
        case "OPEND":
          this.listQuery.status = "OPEND";
          break;

        case "DRAFT":
          this.listQuery.status = "DRAFT";
          break;

        case "CLOSED":
          this.listQuery.status = "CLOSED";
          break;

        case "CANCELED":
          this.listQuery.status = "CANCELED";
          break;

        default:
          this.listQuery.status = undefined;
      }
      this.getData();
    },
    handleCustomer(id) {
      this.listQuery.contactId = id;
      this.getData();
    },
    exportQuoteListPDF() {
      this.exportLoading = true;
      exportQuoteListPDF(this.listQuery)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute(
            "download",
            "devis-" +
              this.listQuery.start +
              "-" +
              this.listQuery.end +
              this.file
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        })
        .catch((err) => {
          console.log(err);
          //console.log("hello");
        });
    },

    exportExcelADocuments() {
      this.exportLoading = true;
      exportExcelAllDocument(this.listQuery)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "commandes.xls"); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleExport(e) {
      if (
        this.listQuery.start === undefined ||
        this.listQuery.end === undefined
      ) {
        this.$message({
          showClose: true,
          message: "Merci de selectionner deux dates valide.",
          type: "error",
        });
      } else {
        switch (e) {
          case "PDF":
            this.exportQuoteListPDF();
            break;
          case "EXCEL":
            this.exportExcelADocuments();
        }
      }
    },
    handleCloseTransfDialog(e) {
      this.dialogTransVisible = e;
    },
    handleTansformQuote(e) {
      this.quoteTransfId = e;
      this.dialogTransVisible = true;
    },
    async fetchStat() {
      await fetchStatistcs(this.listQuery.type)
        .then((res) => {
          this.statistque = Object.assign({}, res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleCopyOrder(e) {
      const h = this.$createElement;
      this.$msgbox({
        title: "Bon de commande N° " + e.number,
        message: h("div", null, [
          h(
            "span",
            null,
            " Voulez vous vraiment dupliquer ce bon de commande  ?"
          ),
        ]),
        showCancelButton: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            copyDocument(e._id, this.listQuery.type)
              .then((res) => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);

                  this.$message({
                    message: "Bon de commande a été dupliqué",
                    type: "success",
                  });
                  console.log(res.data._id);
                  this.$router.push(`/sales/quote/${res.data._id}`);
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    handleCanceledOrder(e) {
      const h = this.$createElement;
      this.$msgbox({
        title: "BON DE COMMANDE N° " + e.number,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment annuler ce bon de commande ?"),
        ]),
        showCancelButton: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            cancelDocument(e._id, this.listQuery.type)
              .then(() => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                  let invoiceItem = this.items.find((el) => el._id === e._id);
                  invoiceItem.status = "CANCELED";
                  this.fetchStat();
                  this.$message({
                    type: "success",
                    message: "Le bon de commande a été annulé",
                  });
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },

    handleCommand(e) {
      if (e.get) {
        this.$router.push(`/sales/orders/${e.get._id}`);
      }
      if (e.update) {
        this.$router.push(`/sales/orders/update/${e.update._id}`);
      }
      if (e.cancel) {
        this.handleCanceledOrder(e.cancel);
      }
      if (e.copy) {
        this.handleCanceledOrder(e.copy);
      }
    },
    exportOrderPDF(e) {
      exportOrderPDF(e._id)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/pdf",
            })
          );
          let iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.src = url;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    handleView(e) {
      this.dialogViewPDF = true;
      exportOrderPDF(e._id)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "application/pdf" })
          );
          this.urlPDF = url;
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    handleClose() {
      this.dialogViewPDF = false;
    },
  },
};
</script>

<style></style>
