<template>
  <div>
    <PageHeader>
      <div class="text-gray-900 uppercase text-2xl font-semibold py-2">
        Gestion des ventes
      </div>
    </PageHeader>

    <div class="flex flex-col">
      <div class="bg-white h-12 pt-2 px-6 pb-0">
        <el-tabs v-model="activeName">
          <el-tab-pane label="FACTURES" name="INVOICE"> </el-tab-pane>
          <el-tab-pane name="ORDER" label="BONS DE COMMANDE"> </el-tab-pane>
          <el-tab-pane name="QUOTE" label="DEVIS"> </el-tab-pane>
          <el-tab-pane name="CREDIT_NOTE" label="NOTES DE CRÉDIT">
          </el-tab-pane>
          <el-tab-pane name="PAYMENT" label="PAIEMENTS "> </el-tab-pane>
          <!--  <el-tab-pane name="RAPPORT" label="RAPPORT DES VENTES"> </el-tab-pane> -->
        </el-tabs>
      </div>
      <keep-alive class="my-3">
        <invoice v-if="activeName === 'INVOICE'" />
        <quote v-if="activeName === 'QUOTE'" />
        <order v-if="activeName === 'ORDER'" />
        <note-credit v-if="activeName === 'CREDIT_NOTE'" />
        <payment v-if="activeName === 'PAYMENT'" />
        <!--  <rapport v-if="activeName === 'RAPPORT'" /> -->
      </keep-alive>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Invoice from "./invoice";
import Quote from "./quote";
import Order from "./order";
import NoteCredit from "./creditnote";
import Payment from "./payment";
//import Rapport from "./rapport";

export default {
  components: {
    PageHeader,
    Invoice,
    Quote,
    Order,
    NoteCredit,
    Payment,
    // Rapport,
  },
  data() {
    return {
      newArticle: false,
      updateArticle: null,
      activeName: "INVOICE",
    };
  },
  watch: {
    activeName(val) {
      this.$router.push(`${this.$route.path}?tab=${val}`);
    },
  },
  created() {
    // init the default selected tab
    const tab = this.$route.query.tab;
    if (tab) {
      this.activeName = tab;
    }
  },
};
</script>

<style lang="scss" scoped></style>
